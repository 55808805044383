import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Result } from './result/result';
import { MocksService } from '../../gepard/mocks/mocks.service';
import { NormalizeTimestampPipe } from '../../app-commons/pipes/normalize-timestamp.pipe';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  results: Result[] = [];
  results$ = new BehaviorSubject<Result[]>([]);

  constructor(
    private mocks: MocksService,
    private normalizeTimestampPipe: NormalizeTimestampPipe
  ) {
  }

  static toRemoteId(value: number | string, length: number = 13): string {
    return ('0000000000000' + value).slice(-1 * length);
  }

  static areEqual(a: Result, b: Result) {
    return a.id === a.id
      && a.microtime === b.microtime
      && a.correction === b.correction
      && a.verification === b.verification
      && a._rev === b._rev;
  }

  requestResults(mockPath: string): Observable<Result[]> {
    return this.mocks.get(mockPath).pipe(
      map(jsonContent => JSON.parse(jsonContent)),
      map(results => results.map(result => ({...result, ...{ createdAt: this.normalizeTimestampPipe.transform(result.createdAt) }})))
    );
  }

  findById(id: number): (Result | undefined) {
    return this.results.find(result => result.id === id);
  }

  findByCreatedAt(createdAt: string): (Result | undefined) {
    return this.results.find(result => result.createdAt === createdAt);
  }

  replaceState(newResults: Result[]) {
    this.results = newResults;
  }

  /**
   * Merges results into state and returns changed.
   * Adds if result.id isn't present in state yet.
   * Replaces result if changed.
   * Keeps result in state ordered.
   *
   * @see areEqual
   * @param {Result[]} newResults
   * @returns {Result[]}
   */
  mergeToState(newResults: Result[]): Result[] {
    const changed = [];
    newResults.forEach(newResult => {
      const oldResult = this.findById(newResult.id);
      if (!oldResult) {
        if (!this.findByCreatedAt(newResult.createdAt)) {
          this.results.push(newResult);
          changed.push(newResult);
        }
      } else if ('_deleted' in newResult && newResult['_deleted'] === true) {
        this.results.splice(this.results.indexOf(oldResult), 1);
        changed.push(newResult);
      } else if (!ResultsService.areEqual(oldResult, newResult)) {
        this.results.splice(this.results.indexOf(oldResult), 1, newResult);
        changed.push(newResult);
      }
    });
    this.sortState();
    return changed;
  }

  sortState() {
    // TODO Should't it be sorted by time instead of ids?
    // this.results.sort((a, b) => a.id - b.id);
    this.results.sort((a, b) => {
      if (a.createdAt === b.createdAt) {
        return 0;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  clearState() {
    this.replaceState([]);
    this.broadcastChanges([]);
  }

  broadcastChanges(changedResults: Result[]) {
    this.results$.next(changedResults);
  }
}
