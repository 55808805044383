import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // Start page:
  {path: '', redirectTo: '/ranking/season-results', pathMatch: 'full'},
  // {path: '', redirectTo: '/ranking/race-results/7', pathMatch: 'full'},
  // 404:
  // { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
