import { Injectable } from '@angular/core';
import { ResultMeta } from './result-meta';

@Injectable({
  providedIn: 'root'
})
export class ResultsMetaService {

  resultsMeta = {};

  constructor() { }

  get(result) {
    if (!(result.id in this.resultsMeta)) {
      this.resultsMeta[result.id] = {
        result: result,
        hovered: false
      } as ResultMeta;
    }
    return this.resultsMeta[result.id];
  }

  hover(result) {
    this.get(result).hovered = true;
  }

  unhover(result) {
    this.get(result).hovered = false;
  }

  isHovered(result) {
    return !!this.get(result).hovered;
  }

  filter(callback) {
    const filtered = {};
    for (const id in this.resultsMeta) {
      if (this.resultsMeta.hasOwnProperty(id) && callback(this.resultsMeta[id])) {
        filtered[id] = this.resultsMeta[id];
      }
    }
    return filtered;
  }

  recalculateDuplicates() {
    for (const id in this.resultsMeta) {
      if (this.resultsMeta.hasOwnProperty(id)) {
        this.resultsMeta[id].duplicates = this.filter(resultMeta =>
          resultMeta.result.microtime === this.resultsMeta[id].result.microtime
          && resultMeta.result.id !== this.resultsMeta[id].result.id
          && resultMeta.result.driverId === this.resultsMeta[id].result.driverId
        );
      }
    }
  }
}
