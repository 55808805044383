<form novalidate #form="ngForm">
  <div class="form-row">
    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="group">Grid group color:</label>
      <select
        id="group"
        class="form-control form-control-sm"
        [value]="group"
        (change)="group = $event.target.value"
      >
        <option value="">All groups</option>
        <option value="1">Green</option>
        <option value="2">Red</option>
        <option value="3">Yellow</option>
        <option value="4">Blue</option>
        <option value="5">Black</option>
        <option value="6">White</option>
        <option value="7">Orange</option>
        <option value="8">Purple</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="category">Main category:</label>
      <select
        id="category"
        class="form-control form-control-sm"
        [value]="category"
        (change)="category = $event.target.value"
      >
        <option value="">All cars</option>
        <option value="11">Pro 1</option>
        <option value="12">Pro 2</option>
        <option value="13">Pro 3</option>
        <option value="14">Pro 4</option>
        <option value="15">Pro 5</option>
        <option value="16">Pro 6</option>
        <option value="21">Street 1</option>
        <option value="22">Street 2</option>
        <option value="23">Street 3</option>
        <option value="24">Street 4</option>
        <option value="25">Street 5</option>
        <option value="26">Street 6</option>
        <option value="40">OPEN class only</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="oemCategory">OEM category:</label>
      <select
        id="oemCategory"
        class="form-control form-control-sm"
        [value]="oemCategory"
        (change)="oemCategory = $event.target.value"
      >
        <option value="">All cars</option>
        <option value="1">OEM 1</option>
        <option value="2">OEM 2</option>
        <option value="3">OEM 3</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="women">Driver gender:</label>
      <select
        id="women"
        class="form-control form-control-sm"
        [value]="women"
        (change)="women = $event.target.value"
      >
        <option value="">All genders</option>
        <option value="1">Women only</option>
        <option value="0">Men only</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="yt">YouTubers:</label>
      <select
        id="yt"
        class="form-control form-control-sm"
        [value]="yt"
        (change)="yt = $event.target.value"
      >
        <option value="">All drivers</option>
        <option value="1">YouTubers only</option>
        <option value="0">No YouTubers only</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="ranking-laps-number">Ranking laps number:</label>
      <input type="number"
             class="form-control form-control-sm"
             id="ranking-laps-number"
             name="rankingLapsNumber"
             [value]="rankingLapsNumber"
             (change)="rankingLapsNumber = $event.target.value"
      />
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="minimum-lap-seconds">Minimal laptime (s):</label>
      <input type="number"
             class="form-control form-control-sm"
             id="minimum-lap-seconds"
             name="minimumLapSeconds"
             [value]="minimumLapSeconds"
             (change)="minimumLapSeconds = $event.target.value"
      >
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="maximum-lap-seconds">Maximal laptime (s):</label>
      <input type="number"
             class="form-control form-control-sm"
             id="maximum-lap-seconds"
             name="maximumLapSeconds"
             [value]="maximumLapSeconds"
             (change)="maximumLapSeconds = $event.target.value"
      >
    </div>
  </div>
</form>
