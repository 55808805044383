import { Pipe, PipeTransform } from '@angular/core';
import { Standing } from '../standing';
import { StandingsConfig } from './standings-config';

@Pipe({
  name: 'standingsFilter'
})
export class StandingsFilterPipe implements PipeTransform {
  transform(standings: Standing[], standingsConfig: StandingsConfig): Standing[] {
    return standings.filter(standing => {
        return (
            standingsConfig.category === undefined
            || (
              Array.isArray(standingsConfig.category)
                ? standingsConfig.category.find(category => category == standing.driver.category)
                : standing.driver.category === standingsConfig.category
            )
          )
          && (standingsConfig.oemCategory === undefined || standing.driver.oemCategory === standingsConfig.oemCategory)
          && (standingsConfig.group === undefined || standing.driver.group === standingsConfig.group)
          && (standingsConfig.pro === undefined || standing.driver.pro === standingsConfig.pro)
          && (standingsConfig.street === undefined || standing.driver.street === standingsConfig.street)
          && (standingsConfig.oem === undefined || standing.driver.oem === standingsConfig.oem)
          && (standingsConfig.women === undefined || standing.driver.women === standingsConfig.women)
          && (standingsConfig.yt === undefined || standing.driver.yt === standingsConfig.yt);
      }
    );
  }
}
