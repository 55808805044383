import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'openUntil'
})
export class NormalizeTimestampPipe implements PipeTransform {

  transform(timestampCandidate: string, args?: any): any {
    return timestampCandidate.replace(/^([0-9]{4}(\-[0-9]{2}){2})\s(([0-9]{2}\:){2}[0-9]{2}\.[0-9]+)$/, '$1T$3');
  }

}
