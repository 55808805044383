import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GateReadingsService } from './gate-readings.service';
import { TagsService } from '../tags/tags.service';
import { GateReading } from './gate-reading';

@Component({
  selector: 'mc-gate-readings',
  templateUrl: './gate-readings.component.html',
  styleUrls: ['./gate-readings.component.scss']
})
export class GateReadingsComponent implements OnInit {

  @Output() updated = new EventEmitter<GateReading[]>();

  gateReadings: GateReading[] = [];

  constructor(
    private tagsService: TagsService,
    private gateReadingsService: GateReadingsService
  ) {
  }

  ngOnInit() {
  }

  update(csvContent: string) {
    this.gateReadings = this.gateReadingsService.parse(csvContent);
    this.updated.emit(this.gateReadings);
  }
}
