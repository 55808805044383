import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ResultsConfig } from '../../results/results-config/results-config';
import { Result } from '../../results/result/result';
import { Standing } from '../../standing';
import { ResultsFilterPipe } from '../../results/results-config/results-filter.pipe';
import { DriversService } from '../../../gepard/drivers/drivers.service';
import { LaptimeRangeFilterPipe } from '../../results/result/laptime-range-filter.pipe';
import { Race } from '../../races/race';
import { RemoteResultsService } from '../../results/remote-results.service';
import { environment } from '../../../../environments/environment';
import { StandingsService } from '../../standings/standings.service';

@Component({
  selector: 'mc-race-ranking-standings-list',
  templateUrl: './race-ranking-standings-list.component.html',
  styleUrls: ['./race-ranking-standings-list.component.scss']
})
export class RaceRankingStandingsListComponent implements OnChanges, OnDestroy {

  @Input() race: Race;
  @Input() standings: Standing[] = [];
  @Input() resultsConfig: ResultsConfig = {};

  @Output() refresh = new EventEmitter<Result>();
  @Output() save = new EventEmitter<Result>();
  @Output() remove = new EventEmitter<Result>();
  @Output() restore = new EventEmitter<Result>();
  @Output() reject = new EventEmitter<Result>();
  @Output() updated = new EventEmitter<Standing[]>();

  bestLapOfRanking$ = new BehaviorSubject<Result | null>(null);

  rowDetailsOpen = {};
  isModerable = false;

  constructor(
    private driversService: DriversService,
    private resultsFilter: ResultsFilterPipe,
    private laptimeRangeFilterPipe: LaptimeRangeFilterPipe,
    private standingsService: StandingsService,
    private remoteResultsService: RemoteResultsService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.standings && changes.standings.currentValue) {
      // console.log('standings changed - recalculate order');
      this.recalculateOrder();
      this.recalculatePositions();
      this.recalculateBestLap();
      if (this.race) {
        this.recalculateSeasonPoints();
      }
    }
    if (changes.race && changes.race.currentValue) {
      this.isModerable = !!environment.isAdmin && !!this.race.driversSpreadsheet;
    }
  }

  recalculateOrder() {
    this.standingsService.recalculateStandingsOrder(this.standings);
  }

  recalculatePositions() {
    this.standingsService.recalculateStandingsPositions(this.standings);
  }

  recalculateSeasonPoints() {
    this.standingsService.recalculateStandingsSeasonPoints(this.standings, this.race.pointsMultiplier);
  }

  recalculateBestLap() {
    this.standingsService.recalculateStandingsBestLap(this.standings);
  }

  trackResult(index: number, result: Result) {
    return result.createdAt;
  }

  trackStanding(index: number, standing: Standing) {
    return standing.driver.startNumber;
  }

  isActionUsed(action: string) {
    return this[action].observers.length > 0;
  }

  toggleVerification(result) {
    if (result.verification === null) {
      this.remoteResultsService.reject(result);
      this.reject.emit(result);
      this.save.emit(result);
    } else if (result.verification === false) {
      this.restore.emit(result);
      this.remoteResultsService.restore(result);
      this.save.emit(result);
    } else if (result.verification === true) {
      this.remoteResultsService.unverify(result);
      this.save.emit(result);
    }
    // this.unhover();
    // this.changed.emit(this.result);
  }

  ngOnDestroy() {
    // console.log('destroy standings');
  }
}
