import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seasonPoints'
})
export class SeasonPointsPipe implements PipeTransform {

  readonly SEASON_POINTS_MAP = [30, 27, 25, 23, 21, 19, 17, 15, 13, 12, 9, 7, 5, 3, 2, 1];
  readonly BEST_LAP_POINTS = 1;

  transform(position: number, multiplier?: number, isBestLap?: boolean): any {
    multiplier = typeof multiplier === 'number' ? multiplier : 1;
    if (position <= this.SEASON_POINTS_MAP.length) {
      return (this.SEASON_POINTS_MAP[position - 1] + (isBestLap ? this.BEST_LAP_POINTS : 0)) * multiplier;
    } else {
      return multiplier + (isBestLap ? this.BEST_LAP_POINTS : 0);
    }
  }
}
