import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { RaceResultsComponent } from './race-results/race-results.component';
import { SeasonResultsComponent } from './season-results/season-results.component';
import { RankingComponent } from './ranking.component';
import { RankingRoutingModule } from './ranking-routing.module';
import { RacesComponent } from './races/races.component';
import { RacesService } from './races/races.service';
import { RankingsService } from './rankings.service';
import { SeasonsModule } from '../seasons/seasons.module';
import { RaceRankingStandingsComponent } from './race-ranking-standings/race-ranking-standings.component';
import { RaceRankingStandingsListComponent } from './race-ranking-standings/race-ranking-standings-list/race-ranking-standings-list.component';
import { StandingsComponent } from './standings/standings.component';
import { StandingsModerationComponent } from './standings/standings-moderation/standings-moderation.component';
import { ResultComponent } from './results/result/result.component';
import { LaptimeRangeFilterPipe } from './results/result/laptime-range-filter.pipe';
import { LaptimeFilterPipe } from './results/result/laptime-filter.pipe';
import { ResultsFilterPipe } from './results/results-config/results-filter.pipe';
import { StandingsFilterPipe } from './standings/standings-filter.pipe';
import { ResultsComponent } from './results/results.component';
import { RemoteResultsService } from './results/remote-results.service';
import { SpreadsheetService } from './results/spreadsheet.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SeasonsModule,
    RankingRoutingModule
  ],
  declarations: [
    RankingComponent,
    ResultsComponent,
    RaceResultsComponent,
    SeasonResultsComponent,
    RacesComponent,
    ResultComponent,
    StandingsComponent,
    StandingsModerationComponent,
    RaceRankingStandingsComponent,
    RaceRankingStandingsListComponent,
    LaptimeRangeFilterPipe,
    LaptimeFilterPipe,
    ResultsFilterPipe,
    StandingsFilterPipe
  ],
  exports: [
    StandingsComponent,
    StandingsModerationComponent,
    RaceRankingStandingsComponent,
    ResultComponent,
    LaptimeFilterPipe
  ],
  providers: [
    RankingsService,
    RacesService,
    LaptimeRangeFilterPipe,
    ResultsFilterPipe,
    StandingsFilterPipe,
    RemoteResultsService,
    SpreadsheetService
  ]
})
export class RankingModule { }
